// import Vue from 'vue'
// import App from './App.vue'
// import vuetify from './plugins/vuetify'
//
// Vue.config.productionTip = false
//
// new Vue({
//   vuetify,
//   render: h => h(App)
// }).$mount('#app')

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'

import router from './router'
import store from './store'
import http from './http';
import HttpClient from "./http/api/connector/http.client";

Vue.config.productionTip = false

Vue.prototype.appName = process.env.VUE_APP_NAME;
// Vue.prototype.$eventBus = EventBus;

Vue.config.productionTip = false
Vue.config.devtools = true;
Vue.config.debug = false;
Vue.config.silent = true;


// handle error
Vue.config.errorHandler = function (err, vm, info) {
  // Global Error Handler
  console.log("Error Handler ", err);
};

let app = {
  router,
  store,
  vuetify,
  render: h => h(App),
  beforeCreate: function () {
    http.registerListeners();
    http.registerStatusListeners();
    HttpClient.init(process.env.VUE_APP_BASE_URL);
  },
};

import serviceContext from './http/api/services/serviceContext'
serviceContext({Vue});


new Vue(app).$mount('#app');