/**
 *
 * @type {{}}
 */
let endpoints = {
    demo:"demo",
    games:"games",
    players:"players",
    gameQuizQuestions:"game-quiz-questions",
    gameSessions:"game-sessions",
    gameVisualContents:"game-visual-contents",
    gameQuizQuestionContents:"game-quiz-questions"
};
export default endpoints;