/**
 *
 * event.bus
 * @author : ONDER SAHIN
 * @year : 2020
 *
 */

// import Vue from 'vue'
// export const EventBus = new Vue()
// export default EventBus


import Phaser from 'phaser'
const eventsCenter = new Phaser.Events.EventEmitter()
export default eventsCenter
